import React, {useState} from "react";

function ContactForm() {

    const initialState ={
        name: '',
        email:'',
        phone: '',
        prom: '',
        message:''
      };

  const [record, setRecord] = useState(initialState);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState("");

  const sendingInfo = async(e)=>{
    setError("");
    e.preventDefault();
    setSending(true);

    var bodyFormData = new FormData();
    bodyFormData.append('name', record.name);
    bodyFormData.append('email', record.email);
    bodyFormData.append('phone', record.phone);
    bodyFormData.append('prom', record.prom);
    bodyFormData.append('message', record.message);

    fetch('/mail.php', {
      method: "post",
      body: bodyFormData,
    })
    .then(res => {
        setSending(false);
        setRecord(initialState);
        return res;
    })
    .catch(error => {
        setSending(false);
        console.log(error);
        setError("El mensaje no se pudo enviar, por favor intenta nuevamente");
        return false;
    });
 }

 const onInputchange =(e)=>{
    setRecord({...record, [e.target.name]: e.target.value});
 };


  return (<form onSubmit={(e)=>sendingInfo(e)}>
      <div className="row g-3">
          <div className="col-md-6">
              <div className="form-floating">
                  <input type="text" name="name" className="form-control" id="nombre" placeholder="Tu nombre" onChange={onInputchange} value={record.name}/>
                  <label htmlFor="nombre">Nombre</label>
              </div>
          </div>
          <div className="col-6">
              <div className="form-floating">
                  <input type="text" name="phone" className="form-control" id="telefono" placeholder="Telefono" onChange={onInputchange} value={record.phone}/>
                  <label htmlFor="telefono">Telefono</label>
              </div>
          </div>
          <div className="col-8">
              <div className="form-floating">
                  <input type="text" name="email" className="form-control" id="correo" placeholder="Correo" onChange={onInputchange} value={record.email}/>
                  <label htmlFor="correo">Correo</label>
              </div>
          </div>
          <div className="col-4">
              <div className="form-floating">
                  <input type="text" name="prom" className="form-control" id="promocion" placeholder="Promocion" onChange={onInputchange} value={record.prom}/>
                  <label htmlFor="promocion">Promocion La salle</label>
              </div>
          </div>
          <div className="col-12">
              <div className="form-floating">
                  <textarea className="form-control" name="message" placeholder="Mensaje" id="message" style={{height: '100px'}} onChange={onInputchange}  value={record.message}>  </textarea>
                  <label htmlFor="message">Mensaje</label>
              </div>
          </div>
          <div className="col-12">
              {error!=="" && <label className="text -danger">{error}&nbsp;&nbsp;&nbsp;</label>}
              {!sending && <button className="btn btn-primary py-2 px-3 me-3" type="submit">
                    {sending? "Enviando...": "Enviar mensaje"}
                    <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                        <i className="fa fa-arrow-right"></i>
                    </div>
                </button>
                }
          </div>
      </div>
  </form>
  )
};

export default ContactForm;
