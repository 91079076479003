import React from "react";
import dataJson from "./data";


const goals = [{
    description: 'Promociones',
    amount: dataJson.promos,
    sufix: 'facts-plus',
    icon: 'flaticon-education'
  },
  {
    description: 'Estudiantes donando',
    amount: dataJson.students,
    sufix: 'facts-plus',
    icon: 'flaticon-healthcare',
  },
  {
    description: 'Nuestra meta',
    amount: dataJson.goal,
    sufix: 'facts-dollar',
    icon: 'flaticon-kindness',
  },
  {
    description: 'Alcanzado',
    amount: dataJson.reach,
    sufix: 'facts-dollar',
    icon: 'flaticon-donation',
  },];

function Goals() {
  return (
    <div className="container">
    <div className="row">
      {goals.map((c,i)=><div key={i} className="col-lg-3 col-md-6">
            <div className="facts-item">
                {c.icon && <i className={c.icon}></i>}
                <div className="facts-text">
                    <h3 className={c.sufix} data-toggle="counter-up">{c.amount}</h3>
                    <p>{c.description}</p>
                </div>
            </div>
        </div> )}
    </div>
</div>
  );
}

export default Goals;
