/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import ContactForm from "./ContactForm";
import VisitasDocentes from "./VisitasDocentes";
import Goals from "./Goals";

const team = [{
  name: 'Ing. Ernesto Toruño',
  title: 'Voluntario',
  image: 'img/ernesto.jpeg',
  wowseconds: "0.1"
},
{
  name: 'Ing. Cecilia Zeledon',
  title: 'Voluntario',
  image: 'img/john-doe.png',
  wowseconds: "0.3"
},
{
  name: 'Docente Cesar Vargas',
  title: 'Voluntario',
  image: 'img/john-doe.png',
  wowseconds: "0.5"
},
{
  name: 'Docente Eduardo Cantero',
  title: 'Voluntario',
  image: 'img/john-doe.png',
  wowseconds: "0.7"
}]

const sliders = [{
  title: 'Cambiemos El Futuro De Nuestros Educadores Con Humanidad',
  message: 'Aliqu diam amet diam et eos. Clita erat ipsum et lorem sed stet lorem sit clita duo justo erat amet',
  image: 'img/carousel-1.jpg',
},
{
  title: 'Debemos Reconocer La Importancia De La Educacion Y Honrar A Quienes La Hacen Posible',
  message: 'Aliqu diam amet diam et eos. Clita erat ipsum et lorem sed stet lorem sit clita duo justo erat amet',
  image: 'img/carousel-2.jpg',
},
{
  title: 'Una Comunidad Unida Es Capaz De Lograr Grandes Cambios.',
  message: 'Aliqu diam amet diam et eos. Clita erat ipsum et lorem sed stet lorem sit clita duo justo erat amet',
  image: 'img/carousel-3.jpg',
}]

const whyhelp=[{
  title: 'Contribuyen a tu futuro',
  message: 'Los docentes desempeñan un papel esencial en la formación de la próxima generación, dejando una huella duradera en la sociedad.',
  icon: 'flaticon-education',
},
{
  title: 'Impacto en la comunidad',
  message: 'El apoyo a los docentes repercute directamente en el bienestar educativo, social y emocional de los estudiantes y sus familias.',
  icon: 'flaticon-social-care',
},
{
  title: 'Merecido Reconocimiento',
  message: 'Apoyar a los docentes es una forma de mostrar gratitud por su dedicación, esfuerzo y sacrificio en su labor diaria.',
  icon: 'flaticon-charity',
}]

const testimonials = [{
    name: 'Nombre de alumno',
    profession: 'Profesion del alumno',
    comment: 'Clita clita tempor justo dolor ipsum amet kasd amet duo justo duo duo labore sed sed. Magna ut diam sit et amet stet eos sed clita erat magna elitr erat sit sit erat at rebum justo sea clita.',
    picture: 'img/testimonial-1.jpg',
},{
    name: 'Nombre de alumno',
    profession: 'Profesion del alumno',
    comment: 'Clita clita tempor justo dolor ipsum amet kasd amet duo justo duo duo labore sed sed. Magna ut diam sit et amet stet eos sed clita erat magna elitr erat sit sit erat at rebum justo sea clita.',
    picture: 'img/testimonial-2.jpg',
},{
    name: 'Nombre de alumno',
    profession: 'Profesion del alumno',
    comment: 'Clita clita tempor justo dolor ipsum amet kasd amet duo justo duo duo labore sed sed. Magna ut diam sit et amet stet eos sed clita erat magna elitr erat sit sit erat at rebum justo sea clita.',
    picture: 'img/testimonial-3.jpg',
}]

function App() {

  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });

    document.querySelector('.back-to-top')?.classList.add('hidden');
};

  useEffect(() => {
    const handleScroll = () => {
      
      const scrollTopElement = document.querySelector('.fixed-top');
      if(window.width < 992){
        if (window.scrollY > 45) {
            scrollTopElement?.classList.add('bg-dark', 'shadow');
        } else {
            scrollTopElement?.classList.remove('bg-dark', 'shadow');
        }
      }
      else{
        if (window.scrollY > 45) {
          scrollTopElement?.classList.add('bg-dark', 'shadow');
        } else {
            scrollTopElement?.classList.remove('bg-dark', 'shadow');
        }
      }

      if (window.scrollY > 300) {
        document.querySelector('.back-to-top')?.classList.remove('hidden');
      }
      
      if (window.scrollY === 0) {
        document.querySelector('.back-to-top')?.classList.add('hidden');
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (<>
    <div className="container-fluid fixed-top px-0 wow fadeIn" data-wow-delay="0.1s">
        <nav className="navbar navbar-expand-lg navbar-dark py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s">
            <a href="index.html" className="navbar-brand ms-4 ms-lg-0">
                <h1 className="fw-bold text-primary m-0">Union<span className="text-white">Lasallista</span></h1>
            </a>
            <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto p-4 p-lg-0">
                    <a href="#" className="nav-item nav-link active">Inicio</a>
                    <a href="#fondoId" className="nav-item nav-link">Mision</a>
                    <a href="#visitasId" className="nav-item nav-link">Visitas</a>
                    <a href="#equipoId" className="nav-item nav-link">Equipo</a>
                    <a href="#testimoniosId" className="nav-item nav-link hidden">Testimonios</a>
                    <a href="#contactId" className="nav-item nav-link">Contactanos</a>
                </div>
                <div className="d-none d-lg-flex ms-2">
                    <a className="btn btn-outline-green py-2 px-3" target="_blank" href="https://shorturl.at/wBPQU" rel="noreferrer">
                        Donar
                        <div className="d-inline-flex btn-sm-square bg-white text-green rounded-circle ms-2">
                            <i className="fa fa-arrow-right"></i>
                        </div>
                    </a>
                </div>
            </div>
        </nav>
    </div>

    <div className="container-fluid p-0 mb-5">
        <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              {sliders.map((sl,i)=> <div key={i} className={`carousel-item ${i===0 ? "active" : ""}`}>
                    <img className="w-100" src={sl.image} alt={sl.image} />
                    <div className="carousel-caption">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 pt-5">
                                    <h1 className="display-4 text-white mb-3 animated slideInDown">{sl.title}</h1>
                                    {/* <p className="fs-5 text-white-50 mb-5 animated slideInDown">{sl.message}</p> */}
                                    <a className="btn btn-primary py-2 px-3 animated slideInDown" target="_blank" href="https://shorturl.at/wBPQU" rel="noreferrer">
                                        Unete al grupo
                                        <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                            <i className="fa fa-arrow-right"></i>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    </div>

    <div id="fondoId" className="container-xxl py-5">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="position-relative overflow-hidden h-100" style={{minHeight: '400px'}}>
                        <img className="position-absolute w-100 h-100 pt-5 pe-5" src="img/mision.jpg" alt="" style={{objectFit: "cover"}} />
                        <img className="position-absolute top-0 end-0 bg-white ps-2 pb-2" src="img/logounion.png" alt="" style={{width: '200px', height: '200px'}} />
                    </div>
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="h-100">
                        <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">Nuestra misión</div>
                        <h2 className="mb-5">Crear un fondo comunitario para docentes retirados del Instituto Pedagógico La Salle</h2>
                        <div className="bg-light border-bottom border-5 border-primary rounded p-4 mb-4">
                            <p className="text-dark mb-2 text-justify">Estamos comprometidos en la creación de un fondo de jubilación sólido y sostenible para nuestros docentes. Nuestro objetivo es garantizar que estos educadores dedicados reciban el apoyo financiero que merecen al final de sus carreras.</p>
                        </div>
                        <p className="mb-5 text-justify">Imaginen por un momento el impacto duradero que podríamos tener si unimos nuestras fuerzas para crear un fondo destinado a ayudar a esos educadores en su jubilación. Este fondo no solo sería un gesto de gratitud, sino una manifestación de nuestro compromiso con la idea de que la educación va más allá de las aulas, es un lazo que nos conecta a todos.</p>
                        <a className="btn btn-primary py-2 px-3 me-3" target="_blank" href="https://shorturl.at/wBPQU" rel="noreferrer">
                            Unete al grupo
                            <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                <i className="fa fa-arrow-right"></i>
                            </div>
                        </a>
                        <a className="btn btn-outline-green py-2 px-3" target="_blank" href="https://shorturl.at/wBPQU" rel="noreferrer">
                            Donar
                            <div className="d-inline-flex btn-sm-square bg-white text-green rounded-circle ms-2">
                                <i className="fa fa-arrow-right"></i>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="whyHelpId"  className="container-xxl py-5">
        <div className="container">
            <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3"></div>
                <h1 className="display-6 mb-5">Porque Debemos Apoyar A Nuestros Educadores?</h1>
            </div>
            <div className="row g-4 justify-content-center">
              {whyhelp.map((wh,i)=> <div key={i} className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item bg-white text-center h-100 p-4 p-xl-5 z">
                        {wh.icon && <i className={wh.icon}></i>}
                        <h4 className="mb-3">{wh.title}</h4>
                        <p className="mb-4">{wh.message}</p>
                    </div>
                </div>)}
            </div>
        </div>
    </div>

    <div id="donarId"  className="container-fluid donate my-5 py-5" data-parallax="scroll" data-image-src="img/mision.jpg">
        <div className="container py-5">
            <div className="row g-5 align-items-center">
                <div className="col-lg-12 wow fadeIn text-center" data-wow-delay="0.1s">
                    <h2 className="text-white mb-5">La jubilación, aunque merecida, a menudo viene acompañada de grandes desafíos financieros para nuestros docentes. Este fondo no solo aliviará esas preocupaciones, sino que también enviará un mensaje claro de que valoramos y apreciamos el arduo trabajo de nuestros educadores.</h2>
                </div>
            </div>
        </div>
    </div>

    <div id="visitasId" className="container-xxl bg-light my-5 py-5">
        <VisitasDocentes />
    </div>

    <div id="equipoId"  className="container-xxl py-5 hidden">
        <div className="container">
            <div className="text-center mx-auto mb-5 wow fadeInUp col-lg-10" data-wow-delay="0.1s">
                <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">Miembros</div>
                <h1 className="display-6 mb-5">Una iniciativa de ex-alumnos en conjunto con algunos ex-docentes del Instituto Pedagógico La Salle</h1>
            </div>
            <div className="row g-4">
              {team.map((t,i)=> <div key={i} className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay={t.wowseconds}>
                    <div className="team-item position-relative rounded overflow-hidden">
                        <div className="overflow-hidden">
                            <img className="img-fluid" src={t.image} alt="" />
                        </div>
                        <div className="team-text bg-light text-center p-4">
                            <h5>{t.name}</h5>
                            <p className="text-primary">{t.title}</p>
                        </div>
                    </div>
                </div>)}
            </div>
        </div>
    </div>

    <div className="facts" data-parallax="scroll" data-image-src="img/facts.jpg">
        <Goals />
    </div>

    <div id="testimoniosId"  className="container-xxl py-5 hidden">
        <div className="container">
            <div className="text-center mx-auto mb-5 wow fadeInUp col-lg-8" data-wow-delay="0.1s">
                <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">Testimonios</div>
                <h1 className="display-6 mb-5">Muchos estudiantes apoyando al proyecto</h1>
            </div>
            <div className="testimonial-carousel wow fadeInUp carousel slide" data-wow-delay="0.1s" data-bs-ride="carousel">
                <div className="carousel-inner owl-item center">
                    {testimonials.map((c,i)=> 
                    <div key={i} className={`testimonial-item text-center carousel-item ${i===0 ? "active" : ""}`}>
                        <img className="img-fluid bg-light rounded-circle p-2 mx-auto mb-4" src={c.picture} style={{width: '100px', height: '100px'}}  alt={c.name}/>
                        <div className="testimonial-text rounded text-center p-4">
                            <p>{c.comment}</p>
                            <h5 className="mb-1">{c.name}</h5>
                            <span className="fst-italic">{c.profession}</span>
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    </div>

    <div id="contactId" className="container-xxl py-5">
        <div className="container">
            <div className="text-center mx-auto mb-5 wow fadeInUp col-lg-10" data-wow-delay="0.1s">
                <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">Contacto</div>
                <h1 className="display-6 mb-5">Si tienes alguna duda, por favor contactanos</h1>
            </div>
            <div className="row g-5">
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                    <div className="position-relative rounded overflow-hidden w-100 h-100">
                        <img src="img/juan_bautista_salle.jpeg" alt="" />
                    </div>
                </div>
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                    <ContactForm />
                </div>
            </div>
        </div>
    </div>


    <div className="container-fluid bg-dark text-white-50 footer mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container-fluid copyright">
            <div className="container">
                <div className="row">
                    <div className="col-md-10 text-center text-md-start mb-3 mb-md-0">
                        &copy; <a href="#">Proyecto Union Lasallista</a> Iniciativa propia de ex-alumnos del Colegio La Salle, todos los derechos reservados 2024.
                    </div>
                </div>
            </div>
        </div>
    </div>

    <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top hidden" onClick={scrollToTop}><i className="bi bi-arrow-up"></i></a>
    </>
  );
}

export default App;
