import React from "react";


const helpTeacher = [{
    name: 'Manuel Latino',
    description: 'Profesor de Física y Matemática.',
    image: '',
    videourl: 'https://www.youtube.com/embed/DDr9mYN1g7k?si=AHzUD5Xd_1kNzkNx&controls=0&rel=0'
  },
  {
    name: 'Mara',
    description: 'Profesor de Natación.',
    image: '',
    videourl: 'https://www.youtube-nocookie.com/embed/2cdbRTWE3WQ?si=MGJz3Rkmi9XEdQTp&start=14&controls=0&rel=0'
  },
  {
    name: 'Ernesto Tapia',
    description: 'Profesor de Educación Física.',
    image: '',
    videourl: 'https://www.youtube.com/embed/LVjlXjjLgoo?si=7w1LmtaflQ3MPvUT&controls=0&rel=0'
  },
  {
    name: 'Esther Osejo',
    description: 'Profesora de Inglés.',
    image: 'img/profe-esther.jpg',
    videourl: "https://www.youtube-nocookie.com/embed/poZkgWUyJaQ?si=XofJg3h7qiVxA7Qd&start=160&end=300&controls=0&rel=0"
  },
  {
    name: 'Eduardo Cantero',
    description: 'Profesor de computación.',
    image: 'img/profe-cantero.jpg',
    videourl: 'https://www.youtube.com/embed/9_i9CY-QDbY?si=OxTtZcCpMI0uCAw6&controls=0&rel=0'
  },
  {
    name: 'Cesar Vargas',
    description: 'Profesor de Física y Matemática.',
    image: '',
    videourl: "https://www.youtube-nocookie.com/embed/poZkgWUyJaQ?si=XofJg3h7qiVxA7Qd&start=328&end=535&controls=0&rel=0"
  },
  {
    name: 'Bismark Lopez',
    description: 'Profesor de Matemática.',
    image: '',
    videourl: "https://www.youtube-nocookie.com/embed/poZkgWUyJaQ?si=XofJg3h7qiVxA7Qd&start=1128&end=1252&controls=0&rel=0"
  },
  {
    name: 'Amalia Duriez',
    description: 'Profesora de Español.',
    image: 'img/profe-amalia.jpg',
    videourl: "https://www.youtube-nocookie.com/embed/poZkgWUyJaQ?si=XofJg3h7qiVxA7Qd&start=47&end=145&controls=0&rel=0"
  },
  {
    name: 'Carlos Carrillo',
    description: 'Profesor de Matemática.',
    image: '',
    videourl: "https://www.youtube-nocookie.com/embed/poZkgWUyJaQ?si=XofJg3h7qiVxA7Qd&start=972&end=1123&controls=0&rel=0",
  },
  {
    name: 'Carlos Morgan',
    description: 'Profesor de Educación Física.',
    image: 'img/profe-morgan.jpg',
    videourl: null
  },
  {
    name: 'Francis',
    description: 'Profesora de Español.',
    image: 'img/profe-francis.jpg',
    videourl: null
  }];

function VisitasDocentes() {
  return (
    <div className="container py-5">
            <div className="text-center mx-auto mb-5 wow fadeInUp col-lg-10" data-wow-delay="0.1s">
                <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">Palabras de nuestros docentes</div>
                <h4 className="display-6 mb-5">Al Invertir en el bienestar de nuestros docentes, estamos invirtiendo en el legado de la educación</h4>
            </div>
            <div className="row g-4 justify-content-center">
                {helpTeacher.map((t,i)=> <div key={i} className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                        <div className="text-center pt-0">
                            <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                                <small>{t.name}</small>
                            </div>
                            <h5 className="mb-3">{t.description}</h5>
                            {/* <p>Tempor erat elitr rebum at clita dolor diam ipsum sit diam amet diam et eos</p> */}
                        </div>
                        <div className="position-relative mt-auto">
                          {!t.videourl && <>
                            <img className="img-fluid" src={t.image} alt="" />
                            <div className="causes-overlay">

                            </div>
                          </>}
                          {t.videourl && <div className="video-embed">
                            <iframe src={t.videourl} 
                                title="YouTube video player"
                                frameBorder="0"
                                allow=""
                                allowFullScreen />
                          </div>
}
                        </div>
                    </div>
                </div>)}
            </div>
        </div>
  );
}

export default VisitasDocentes;
